import axios from "../axios";

export const getIdos = async () => {
  try {
    const endPoint = "api/ido/";
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw "Something went wrong";
    return res.data;
  } catch (err: any) {
    console.log("error while getIdos API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const getPayouts = async (id: string) => {
  try {
    const endPoint = `api/dashboard/payouts/?allocation__ido__id=${id}`;
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw "Something went wrong";
    return res.data;
  } catch (err: any) {
    console.log("error while getIdos API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const getDashboardIdos = async () => {
  try {
    const endPoint = "api/dashboard/payout-overview/";
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw "Something went wrong";
    return res.data;
  } catch (err: any) {
    console.log("error while getIdos API>>>", err.response.data);
    return Promise.reject(err);
  }
};
