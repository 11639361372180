import React, { useState, useEffect } from "react";
import { Table, Button, Row, message, Layout, Tooltip } from "antd";
import Web3 from "web3";
import { useAccount } from "wagmi";
import { erc20Abi } from "viem";
import { getfilledAllocations } from "../api/ApiCalls";
import BigNumber from "bignumber.js";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Text from "../components/Text";
import { feeCal } from "../Services/FeeCalculation";
import { QuestionCircleOutlined } from "@ant-design/icons";

const Allocations = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const { connector, address } = useAccount();
  const { open } = useWeb3Modal();
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  // Fetch data from your endpoint
  const fetchData = async () => {
    const allocations = await getfilledAllocations();

    try {
      setData(allocations);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    {
      title: "User Paid",
      dataIndex: "amount_paid",
      key: "amount_paid",
      render: (text: any, record: any) => (
        <span>
          {text} {record?.exchange_token_used?.symbol}
        </span>
      ),
    },
    {
      title: (
        <Row style={{ gap: "5px", alignItems: "center" }}>
          User Received
          <Tooltip title="Total token amount user received with applied Acquire fee">
            <QuestionCircleOutlined />
          </Tooltip>
        </Row>
      ),
      dataIndex: "amount_received",
      key: "amount_received",
      render: (text: any, record: any) => (
        <span>
        {feeCal(
          Number(record.ido?.acquire_fee),
          Number(text)
        )}{" "}
        {record.ido.token.symbol} (100%)
      </span>
      ),
    },
    {
      title: "Status ",
      key: "status",
      render: (text: any, record: any) => (
        <Button type="primary">Filled</Button>
      ),
    },
    {
      title: "Offer name",
      key: "offername",
      render: (text: any, record: any) => (
        // <Button disabled={!address}>Transfer Tokens</Button>
        <span>{record.ido.token_name}</span>
      ),
    },
    {
      title: "User Email",
      key: "email",
      render: (text: any, record: any) => <span>{record?.owner?.email}</span>,
    },
    {
      title: "User Wallet",
      key: "wallet",
      render: (text: any, record: any) => <span>{record?.owner?.wallet_address}</span>,
    },
  ];

  if (loading) return <p>Loading...</p>;

  return (
    <Layout
      style={{
        backgroundColor: "#0C0A1F",
        alignItems: "center",
        padding: md ? "36px 48px" : sm ? "24px 24px" : " 24px 16px",
      }}
    >
      {/* <Row style={{ margin: "20px" }}>
        {!address && (
          <Button
            type="primary"
            size="large"
            onClick={() => open({ view: "Networks" })}
          >
            Connect wallet
          </Button>
        )}
        {address && (
          <Button size="large" type="primary" onClick={() => open()}>
            {address}
          </Button>
        )}
      </Row> */}

      <Row
        style={{
          maxWidth: "1282px",
          width: "100%",
          flexDirection: "column",
          overflow: "auto",
          gap: "20px",
        }}
      >
        {!address && (
          <Row>
            <Text size="md">Connect wallet in order to transfer </Text>
          </Row>
        )}
        <Row>
          {" "}
          <Table
            dataSource={data}
            columns={columns}
            rowKey="id"
            style={{ width: "100%" }}
          />
        </Row>
      </Row>
    </Layout>
  );
};

export default Allocations;
