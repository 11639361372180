import { Button, Col, Drawer, Menu, MenuProps, Row } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../redux/reducers";
import { useNavigate } from "react-router-dom";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import LogoHeader from "../assets/LogoHeader.svg";
import LogoHeaderMobile from "../assets/MobileLogoHeader.svg";
import { UserOutlined, MenuOutlined, LoginOutlined } from "@ant-design/icons";
import Text from "../components/Text";
import { useSelector } from "react-redux";
import { AllMenuState } from "../redux/reducers";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { SideBarMenu } from "../components/SideBar";

const Header = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const { open } = useWeb3Modal();
  const { connector, address } = useAccount();
  const ActiveMenu = useSelector(AllMenuState);
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const onClose = () => {
    setOpenDrawer(false);
  };
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  return (
    <>
      <Row
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#120F2D",
          padding: xs ? "12px  24px" : "20px 24px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        }}
      >
        <img src={xs ? LogoHeaderMobile : LogoHeader} />

        {md && (
          <Col style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            {/* {(ActiveMenu === "Unfulfilled Allocations" ||
              ActiveMenu === "Fulfilled Allocations") && ( */}
            <>
              {!address && (
                <Button onClick={() => open({ view: "Networks" })}>
                  Connect Wallet
                </Button>
              )}
              {address && (
                <Button onClick={() => open()}>{shortenString(address)}</Button>
              )}
            </>
            {/* )} */}
            <Button
              type="primary"
              onClick={() => {
                dispatch(logout());
              }}
              icon={<UserOutlined />}
            >
              Logout
            </Button>
          </Col>
        )}
        {!md && (
          <Col style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <Text size="md">{ActiveMenu}</Text>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "6px",
                backgroundColor: "#1F1F1F",
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={showDrawer}
            >
              <MenuOutlined />
            </Col>
          </Col>
        )}
      </Row>
      <DrawerSider openDrawer={openDrawer} onClose={onClose} />
    </>
  );
};

export default Header;

interface DrawerSiderProps {
  openDrawer: boolean;
  onClose: () => void;
}

const DrawerSider = (prop: DrawerSiderProps) => {
  const { onClose, openDrawer } = prop;
  const { open } = useWeb3Modal();
  const { connector, address } = useAccount();
  const dispatch = useDispatch();

  return (
    <Drawer
      placement={"left"}
      closable={false}
      onClose={onClose}
      open={openDrawer}
      key={"left"}
      width={"258px"}
    >
      <Row
        style={{
          width: "100%",
          height: "100%",
          flexDirection: "column",
          padding: "12px 20px 36px 20px",
        }}
      >
        <Row style={{ padding: "24px 20px" }}>
          <img src={LogoHeader} />
        </Row>
        <Row style={{ marginTop: "16px" }}>
          <SideBarMenu />
        </Row>
        <Row style={{ marginTop: "16px" }}>
          {!address && (
            <Button
              onClick={() => {
                open({ view: "Networks" });
                onClose();
              }}
              style={{ width: "100%" }}
            >
              Connect Wallet
            </Button>
          )}
          {address && (
            <Button
              onClick={() => {
                open();
                onClose();
              }}
              style={{ width: "100%" }}
            >
              {shortenString(address)}
            </Button>
          )}
        </Row>
        <Row style={{ flexGrow: 1, alignItems: "flex-end" }}>
          <button
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              borderRadius: "6px",
              border: "1px solid #424242",
              padding: "6px",
              backgroundColor: "#120F2D",
              color: "white",
            }}
            onClick={() => {
              dispatch(logout());
            }}
          >
            {" "}
            <LoginOutlined />
            Logout
          </button>
        </Row>
      </Row>
    </Drawer>
  );
};

function shortenString(inputString: string) {
  if (inputString.length <= 7) {
    return inputString;
  }

  return (
    inputString.substring(0, 4) +
    "..." +
    inputString.substring(inputString.length - 4)
  );
}
