import React, { useState, useEffect } from "react";

interface CountdownInterface {
  endDateString: string;
}

function Countdown(props: CountdownInterface) {
  const { endDateString } = props;

  const [remainingTime, setRemainingTime] = useState("");

  useEffect(() => {
    const updateCountdown = () => {
      const endDate = new Date(Date.parse(endDateString));
      const now = new Date();
      const timeDifference = endDate.getTime() - now.getTime();

      const seconds = Math.floor(timeDifference / 1000) % 60;
      const minutes = Math.floor(timeDifference / (1000 * 60)) % 60;
      const hours = Math.floor(timeDifference / (1000 * 60 * 60)) % 24;
      const days: any = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      let formattedTime;

      if (!Number.isNaN(days)) {
        formattedTime = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      } else {
        formattedTime = "";
      }

      setRemainingTime(formattedTime);
    };

    updateCountdown();

    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return <>{remainingTime}</>;
}

export default Countdown;
