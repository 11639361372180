import axios from "../axios";

interface Payload {
  email: string;
  password: string;
}

interface AuthenticationResponse {
  access_token: string;
  refresh_token: string;
  user_id: number;
  wallet_address: string | null;
}

interface ResLogin {
  data: AuthenticationResponse;
  status: string;
}

export const LoginAPI = async (payload: Payload) => {
  //console.log("Login API call!");
  try {
    const endPoint = "api/user/login/?auth_type=ACCOUNT";
    const res = await axios.post<ResLogin>(endPoint, payload);
    if (!res?.data) throw "Something went wrong";
    //console.log("Login API response", res);
    return res.data;
  } catch (err: any) {
    console.log("error while login API>>>", err.response.data);
    return Promise.reject(err);
  }
};
