import { combineReducers } from "redux";
import { user, app, ido, allusers, activeMenu } from "./reducers";

const createRootReducer = () =>
  combineReducers({
    user,
    app,
    ido,
    allusers,
    activeMenu,
  });

export default createRootReducer;
