import React, { useEffect, useState } from "react";
import { Tabs, Layout, Row, Button, Table } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Text from "../components/Text";
import { useNavigate } from "react-router-dom";
import { getDashboardIdos, getIdos } from "../api/ApiCalls/idos";
import { ValuesFormatDecimalAndCommas } from "../Services/CommonServices";
import Countdown from "../components/Countdown";

const { TabPane } = Tabs;

const Payouts = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [idos, setIdos] = useState([]);
  const navigate = useNavigate();

  const columns = [
    {
      title: "Offer name",
      dataIndex: "token_name",
      key: "token_name",
    },
    {
      title: "Total Raised",
      key: "amount",
      render: (record: any) => {
        return (
          <Text size="sm">
            {ValuesFormatDecimalAndCommas(record?.amount_sold)}{" "}
            {record?.token?.symbol}
          </Text>
        );
      },
    },
    {
      title: "Tokens to sell",
      //dataIndex: "amount",
      key: "amount",
      render: (record: any) => {
        return (
          <Text size="sm">
            {ValuesFormatDecimalAndCommas(record?.amount)}{" "}
            {record?.token?.symbol}
          </Text>
        );
      },
    },
    {
      title: "Next payout",
      key: "next_payout",
      render: (record: any) => {
        return (
          <Text size="sm">
            <Countdown endDateString={record.next_payout} />
          </Text>
        );
      },
      width: "180px",
    },
    {
      title: "Amount Of Investors",
      dataIndex: "investors",
      key: "investors",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Text
          size="sm"
          style={{ cursor: "pointer", color: "#33FFFF" }}
          onClick={() =>
            navigate(`/payouts/${record.id}`, {
              state: { token_name: record.token_name },
            })
          }
        >
          Go to payout
        </Text>
      ),
    },
  ];

  useEffect(() => {
    const loadData = async () => {
      const idos = await getIdos();
      console.log("IDOOOO", idos);
      setIdos(idos);
    };

    loadData();
  }, []);

  return (
    <Layout
      style={{
        backgroundColor: "#0C0A1F",
        alignItems: "center",
        padding: md ? "36px 48px" : sm ? "24px 24px" : " 24px 16px",
      }}
    >
      <Row
        style={{
          maxWidth: "1282px",
          width: "100%",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Table
          dataSource={idos}
          columns={columns}
          rowKey="id"
          style={{ width: "100%" }}
        />
      </Row>
    </Layout>
  );
};

export default Payouts;
