import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState: string = "";

export const ActiveMenu = createSlice({
  name: "activeMenu",
  initialState,
  reducers: {
    setMenuState: (state, action: PayloadAction<string>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setMenuState } = ActiveMenu.actions;

export const AllMenuState = (state: RootState) => state.activeMenu;

export default ActiveMenu.reducer;
