import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AllUsers } from "../../utils/types";

const initialState: AllUsers[] = [];

export const AllUsersSlice = createSlice({
  name: "allusers",
  initialState,
  reducers: {
    setAllUsers: (state, action: PayloadAction<AllUsers[]>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setAllUsers } = AllUsersSlice.actions;

export const AllUsersState = (state: RootState) => state.allusers;

export default AllUsersSlice.reducer;
