import { Button, ConfigProvider, Modal, Row } from "antd";
import React, { useState } from "react";
import Text from "./Text";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

interface DeleteModalProps {
  title: string;
  text1: string;
  text2?: string;
  open: boolean;
  onCancel: () => void;
  onDelete: () => void;
  DeleteButtonName: string;
  isDeleting?: boolean;
}

const DeleteModal = (props: DeleteModalProps) => {
  const { onCancel, onDelete, open, text1, text2, title, DeleteButtonName, isDeleting } =
    props;
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            dangerColor: "white",
          },
          Modal: {
            colorIcon: "white",
          },
        },
      }}
    >
      <Modal
        footer={false}
        open={open}
        onCancel={onCancel}
        centered
        width={sm ? 450 : 300}
        styles={{
          content: {
            padding: "36px",
            backgroundColor: "#120F2D",
          },
        }}
      >
        <Row>
          <Text
            size="sm"
            style={{
              width: "100%",
              textAlign: "center",
              marginBottom: "20px",
              fontSize: "20px",
            }}
          >
            {title}
          </Text>
        </Row>

        <Row>
          <Text
            size="sm"
            style={{ width: "100%", textAlign: "center", color: "#909090" }}
          >
            {text1}
          </Text>
        </Row>

        {text2 && (
          <Row>
            <Text
              size="sm"
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: "20px",
                color: "#909090",
              }}
            >
              {text2}
            </Text>
          </Row>
        )}

        <Row style={{ marginTop: "20px", width: "100", gap: "24px" }}>
          <Button onClick={onCancel} style={{ flex: 1 }} size="large">
            Cancel
          </Button>
          <Button
            type="primary"
            danger
            onClick={onDelete}
            style={{ flex: 1 }}
            size="large"
            loading={isDeleting}
            disabled={isDeleting}
          >
            {DeleteButtonName}
          </Button>
        </Row>
      </Modal>
    </ConfigProvider>
  );
};

export default DeleteModal;
