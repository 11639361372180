import { Button, Layout, Row, Table, TableProps, message } from "antd";
import React, { useEffect, useState } from "react";
import { GetAllUsers, deleteUsers } from "../api/ApiCalls/AllUsers";
import { useDispatch, useSelector } from "react-redux";
import { AllUsersState, setAllUsers } from "../redux/reducers";
import { AllUsers as allUsersType } from "../utils/types";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Text from "../components/Text";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import DeleteModal from "../components/DeleteModal";

const AllUsers = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useDispatch();
  const alluserList = useSelector(AllUsersState);
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  const fetchAllUsers = async () => {
    try {
      const res = await GetAllUsers();
      if (res) {
        console.log("Get All User Response ", res);
        dispatch(setAllUsers(res));
      }
    } catch (err) {
      console.log("Error While Fetch All Users ", err);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  interface AllUsers {
    id: number;
    username: string;
    email: string;
    wallet_address: string;
    full_name: string | null;
    email_address: string | null;
  }

  const columns: TableProps<allUsersType>["columns"] = [
    {
      title: "Wallet Address",
      dataIndex: "wallet_address",
      key: "wallet_address",
      render: (text, record) => {
        return md ? (
          <p>{text}</p>
        ) : (
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Text size="sm">Wallet Address</Text>
            <Text size="sm">{shortenString(record.wallet_address)}</Text>
          </Row>
        );
      },
    },
    // {
    //   title: "User Name",
    //   dataIndex: "username",
    //   key: "username",
    //   responsive: ["md"],
    // },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      responsive: ["md"],
    },

    {
      title: "ACQ Balance",
      dataIndex: "acq_balance",
      key: "acq_balance",
      responsive: ["md"],
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: allUsersType[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: allUsersType) => ({
      disabled: record.email === "Disabled User", // Column configuration not to be checked
      name: record.email,
    }),
  };

  const handleDelete = () => {
    setIsDeleteModalVisible(true);
  };

  const confirmDelete = async () => {
    setIsDeleting(true);
    try {
      const userIds = selectedRowKeys.map((key) => Number(key));
      const result = await deleteUsers(userIds);

      if (result.deleted_count > 0) {
        await fetchAllUsers();
        setSelectedRowKeys([]);
        setIsDeleteModalVisible(false);
        message.success(`Successfully deleted ${result.deleted_count} user(s)`);
      } else {
        message.warning("No users were deleted");
      }
    } catch (err) {
      console.error("Error deleting users:", err);
      message.error("Failed to delete users");
    } finally {
      setIsDeleting(false);
    }
  };

  const exportToCSV = async () => {
    try {
      if (alluserList.length > 0) {
        const csvRows = [];
        const headers = [
          "id",
          "username",
          "wallet_address",
          "kyc_complete",
          "kyc_status",
          "bitcoin_address",
          "bitcoin_ordinals_address",
          "solana_address",
          "sui_address",
          "acq_balance",
        ];
        csvRows.push(headers.join(","));

        alluserList.forEach((row: any) => {
          const values = [
            row.id,
            row.username,
            row.wallet_address,
            row.kyc_complete,
            row.kyc_status,
            row.bitcoin_address,
            row.bitcoin_ordinals_address,
            row.solana_address,
            row.sui_address,
            row.acq_balance,
          ];
          csvRows.push(values.join(","));
        });

        const csvContent = csvRows.join("\n");
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "all_users.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Layout
      style={{
        backgroundColor: "#0C0A1F",
        alignItems: "center",
        padding: md ? "36px 48px" : sm ? "24px 24px" : " 24px 16px",
      }}
    >
      <Row
        style={{
          alignItems: "flex-end",
          justifyContent: "end",
          width: "100%",
        }}
      >
        {selectedRowKeys.length > 0 ? (
          <Button type="primary" danger onClick={handleDelete}>
            Delete selected user(s)
          </Button>
        ) : (
          <Button type="primary" onClick={exportToCSV}>
            Export CSV
          </Button>
        )}
      </Row>

      <Row
        style={{
          maxWidth: "1282px",
          width: "100%",
          //flexDirection: "column",
          //overflow: "auto",
          overflowY: "auto",
        }}
      >
        <Row style={{ padding: "8px" }}>
          <Text size="sm">All Users</Text>
        </Row>

        <Table
          size="large"
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          style={{ width: "100%" }}
          columns={columns}
          dataSource={alluserList.map((item) => ({
            ...item,
            key: item.id,
          }))}
          expandable={
            !md
              ? {
                  expandedRowRender: (record) => (
                    <div style={{ padding: "0px", margin: "-16px" }}>
                      {/* <Row className="recent_Offer_tabel_row">
                        <Text size="sm">User name</Text>
                        <Text size="sm">{shortenString(record.username)}</Text>
                      </Row>{" "} */}
                      <Row className="recent_Offer_tabel_row">
                        <Text size="sm">Email</Text>
                        <Text size="sm">{record.email}</Text>
                      </Row>{" "}
                      <Row className="recent_Offer_tabel_row">
                        <Text size="sm">Full Name</Text>
                        <Text size="sm">{record.full_name}</Text>
                      </Row>
                    </div>
                  ),
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <UpOutlined
                        onClick={(e: any) => onExpand(record, e)}
                        style={{ color: "#33FFFF" }}
                      />
                    ) : (
                      <DownOutlined
                        onClick={(e: any) => onExpand(record, e)}
                        style={{ color: "#909090" }}
                      />
                    ),
                  expandIconColumnIndex: 3,
                }
              : undefined
          }
        />
      </Row>
      <DeleteModal
        title="Delete selected user(s)"
        text1="You are about to delete user(s). By doing so, you will also delete all participants, transactions, and other data for this user(s). You cannot undo this action."
        open={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        onDelete={confirmDelete}
        DeleteButtonName="Permanently delete user"
        isDeleting={isDeleting}
      />
    </Layout>
  );
};

export default AllUsers;

function shortenString(str: string, length = 4) {
  if (str.length <= length * 2 + 3) {
    return str;
  }
  const start = str.substring(0, length);
  const end = str.substring(str.length - length);
  return `${start}...${end}`;
}
