import axios from "../axios";
import { Tokens } from "../../utils/";

export const GetTokens = async () => {
  console.log("Get Tokens API call!");
  try {
    const endPoint = "api/tokens/";
    const res = await axios.get<Array<Tokens>>(endPoint);
    if (!res?.data) throw "Something went wrong";
    console.log("Get Tokens API response", res);
    return res.data;
  } catch (err: any) {
    console.log("error while Get Tokens API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const CreateTokenApi = async (payload: FormData) => {
  console.log("CreateToken API call!");
  try {
    const endPoint = "api/tokens/";
    const res = await axios.post<any>(endPoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (!res?.data) throw "Something went wrong";
    console.log("CreateToken API response", res);
    return res.data;
  } catch (err: any) {
    console.log("error while CreateToken API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const DeleteToken = async (id: string) => {
  try {
    const endPoint = `api/tokens/${id}`;
    const res = await axios.delete<any>(endPoint);
    if (!res) throw "Something went wrong";
    //console.log("Response of delete token api", res);
    return res.status;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const EditTokenApi = async (id: string, payload: FormData) => {
  console.log("EditIdo API call!");
  try {
    const endPoint = `api/tokens/${id}/`;
    const res = await axios.patch<any>(endPoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (!res?.data) throw "Something went wrong";
    console.log("EditIdo API response", res);
    return res.data;
  } catch (err: any) {
    console.log("error while CreateIdo API>>>", err.response.data);
    return Promise.reject(err);
  }
};
