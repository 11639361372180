import Sider from "antd/es/layout/Sider";
import Text from "./Text";
import { Menu, MenuProps } from "antd";
import { useDispatch } from "react-redux";
import { setMenuState } from "../redux/reducers";

import {
  PlusCircleOutlined,
  HomeOutlined,
  UserOutlined,
  MoneyCollectOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const SideBar = () => {
  return (
    <Sider
      width={268}
      style={{ backgroundColor: "#120F2D", padding: "24px 16px" }}
    >
      <SideBarMenu />
    </Sider>
  );
};

export default SideBar;

interface SideBarMenuProp {
  width?: string;
}

export const SideBarMenu = (props: SideBarMenuProp) => {
  const { width } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group",
    onClick?: () => void
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
      onClick,
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem("Offer Overview", "1", <HomeOutlined />, undefined, undefined, () => {
      navigate("/");
      dispatch(setMenuState("Home"));
    }),
    getItem("All Users", "2", <UserOutlined />, undefined, undefined, () => {
      navigate("/user");
      dispatch(setMenuState("Users"));
    }),
    getItem("Allocations", "sub1", <MoneyCollectOutlined />, [
      getItem("Unfulfilled", "4", undefined, undefined, undefined, () => {
        navigate("/unfillallocations");
        dispatch(setMenuState("Unfulfilled Allocations"));
      }),
      getItem("Fulfilled", "5", undefined, undefined, undefined, () => {
        navigate("/allocations");
        dispatch(setMenuState("Fulfilled Allocations"));
      }),
    ]),
    // getItem("IDO's", "6", <PlusCircleOutlined />, undefined, undefined, () => {
    //   navigate("/create");
    //   dispatch(setMenuState("IDO's"));
    // }),
    getItem("Token", "7", <PlusCircleOutlined />, undefined, undefined, () => {
      navigate("/tokens-overview");
      dispatch(setMenuState("Token"));
    }),
    getItem("Payouts", "8", <DollarOutlined />, undefined, undefined, () => {
      navigate("/payouts");
      dispatch(setMenuState("Payouts"));
    }),
  ];

  return (
    <Menu mode="inline" items={items} style={{ width: width ? width : "" }} />
  );
};
