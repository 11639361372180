export function ValuesFormatDecimalAndCommas(input: string | undefined) {
  if (input === undefined) {
    return;
  }
  let number = parseFloat(input);
  if (isNaN(number)) {
    return "Invalid input";
  }
  let [integerPart, decimalPart] = number.toFixed(2).split(".");
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (parseInt(decimalPart) === 0) {
    return integerPart;
  } else {
    return `${integerPart}.${decimalPart}`;
  }
}
