import { Button, Checkbox, Form, Input, Row, message } from "antd";
import Layout from "antd/es/layout/layout";
import React, { useState } from "react";
import { LoginAPI } from "../api/ApiCalls";
import { useDispatch } from "react-redux";
import { login } from "../redux/reducers";
import logo from "../assets/logo.svg";
import background from "../assets/login.svg";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Text from "../components/Text";
import { UserOutlined } from "@ant-design/icons";

const Login = () => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [loading, setLoading] = useState<boolean>(false);

  const LoginFun = async (values: any) => {
    setLoading(true);
    try {
      const res = await LoginAPI({
        email: values.username,
        password: values.password,
      });
      if (res) {
        const payload = {
          user: res.data.wallet_address,
          isAuthenticated: true,
          token: res.data.access_token,
          refreshToken: res.data.refresh_token,
        };
        dispatch(login(payload));
      }
      console.log("res inside login", res);
      setLoading(false);
    } catch (err: any) {
      const errMessage = err.response.data.message;
      error(errMessage);
      console.log(errMessage);
      setLoading(false);
    }
  };

  const error = (errMessage: string) => {
    messageApi.open({
      type: "error",
      content: errMessage,
    });
  };
  const onFinish = (values: any) => {
    //console.log("Password: ", values.password);
    //console.log("UserName: ", values.username);
    LoginFun(values);
  };
  return (
    <Layout
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#0C0A1F",
      }}
    >
      {contextHolder}
      <Row
        style={{
          justifyContent: "center",
          backgroundColor: "#120F2D",
          borderRadius: "12px",
          boxShadow:
            "0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
          width: md ? "438px" : sm ? "432px" : "367px",
          padding: md ? "36px" : sm ? "24px" : "24px",
        }}
      >
        <img
          src={logo}
          style={{
            height: sm ? "46px" : "36px",
            width: sm ? "162px" : "137px",
            marginBottom: "32px",
          }}
        />

        <Form
          name="normal_login"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          style={{ width: "100%" }}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              placeholder="Email"
              prefix={<UserOutlined style={{ color: "#424242" }} />}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password type="password" placeholder="Password" />
          </Form.Item>

          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "baseline",
              width: "100%",
            }}
          >
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <Text size="sm" style={{ color: "#33FFFF", cursor: "pointer" }}>
              Forgot password
            </Text>
          </Row>

          <Form.Item>
            <Button
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
              size="middle"
              loading={loading}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Row>
    </Layout>
  );
};

export default Login;
