import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.scss";
import { useSelector } from "react-redux";
import { userState } from "./redux/reducers";
import { Layout } from "antd";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Header from "./components/Header";
import Create from "./pages/Create";
import AllUsers from "./pages/AllUsers";
import Allocations from "./pages/Allocations";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import SideBar from "./components/SideBar";
import UnfilledAllocation from "./pages/UnfilledAllocation";
import EditIDO from "./pages/EditIDO";
import TokensOverview from "./pages/TokensOverview";
import Payouts from "./pages/Payouts";
import PayoutDetail from "./pages/PayoutDetail";
import CreateToken from "./pages/CreateToken";

function App() {
  const user = useSelector(userState);

  return (
    <Router>
      <Layout style={{ minHeight: "100vh" }}>
        {user.isAuthenticated ? <LoggedInRoutes /> : <NotLoggedInRoutes />}
        {/* {true ? <LoggedInRoutes /> : <NotLoggedInRoutes />} */}
      </Layout>
    </Router>
  );
}

export default App;

const LoggedInRoutes = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  return (
    <Layout style={{ height: "100vh", padding: "0px" }}>
      <Header />
      <Layout style={{ backgroundColor: "#120F2D" }}>
        {md && <SideBar />}
        <Routes>
          <Route path="/user" element={<AllUsers />} />
          <Route path="/create" element={<Create />} />
          <Route path="/allocations" element={<Allocations />} />
          <Route path="/unfillallocations" element={<UnfilledAllocation />} />
          <Route path="/edit" element={<EditIDO />} />
          <Route path="/tokens-overview" element={<TokensOverview />} />
          <Route path="/payouts" element={<Payouts />} />
          <Route path="/payouts/:id" element={<PayoutDetail />} />
          <Route path="/create-token" element={<CreateToken />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Layout>
    </Layout>
  );
};

const NotLoggedInRoutes = () => {
  return (
    <Layout style={{ height: "100vh", padding: "0px" }}>
      <Routes>
        <Route path="*" element={<Login />} />
      </Routes>
    </Layout>
  );
};
