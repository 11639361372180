import { Button, Layout, Row, Table, TableProps } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Text from "../components/Text";
import { Tokens } from "../utils";
import { GetTokens } from "../api/ApiCalls/Tokens";

const TokensOverview = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const navigate = useNavigate();
  const [tokensList, setTokensList] = useState<Tokens[]>();

  const columns: TableProps<Tokens>["columns"] = [
    {
      title: "Token name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Network",
      render: (record: Tokens) => {
        return (
          <Text size="sm" style={{ color: "#909090" }}>
            {record.network.name}
          </Text>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.wallet_address === "Disabled User", // Column configuration not to be checked
      name: record.wallet_address,
    }),
  };

  const getToken = async () => {
    try {
      const res = await GetTokens();
      if (res) {
        console.log("TOkenssssss", res);
        setTokensList(res);
      }
    } catch (err) {
      console.log("Error While Fecth tokens ", err);
    }
  };

  useEffect(() => {
    getToken();
  }, []);
  return (
    <Layout
      style={{
        backgroundColor: "#0C0A1F",
        alignItems: "center",
        padding: md ? "36px 48px" : sm ? "24px 24px" : " 24px 16px",
      }}
    >
      <Row
        style={{
          maxWidth: "1282px",
          width: "100%",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Row
          style={{
            padding: "8px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text size="sm">All Tokens</Text>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            style={{ fontWeight: "400" }}
            onClick={() => {
              navigate("/create-token");
            }}
          >
            Create new token
          </Button>
        </Row>

        <Row style={{ minWidth: "857px", overflowX: "auto" }}>
          <Table
            size="large"
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  navigate("/create-token", { state: { obj: { ...record } } });
                },
                style: { cursor: "pointer" },
              };
            }}
            columns={columns}
            dataSource={tokensList?.map((token) => ({
              key: token.id,
              ...token,
            }))}
            style={{ width: "100%" }}
          />
        </Row>
      </Row>
    </Layout>
  );
};

export default TokensOverview;
