import axios from "../axios";

import {
  TokenAPIType,
  NetworkAPIType,
  CreateIdoPayload,
  IdoListType,
} from "../../utils/types";

export const Tokens = async () => {
  //console.log("Get Tokens API call!");
  try {
    const endPoint = "api/tokens/";
    const res = await axios.get<Array<TokenAPIType>>(endPoint);
    if (!res?.data) throw "Something went wrong";
    //console.log("Get Tokens API response", res);
    return res.data;
  } catch (err: any) {
    console.log("error while Get Tokens API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const Networks = async () => {
  //console.log("Get Networks API call!");
  try {
    const endPoint = "api/networks/";
    const res = await axios.get<Array<NetworkAPIType>>(endPoint);
    if (!res?.data) throw "Something went wrong";
    //console.log("Get Networks API response", res);
    return res.data;
  } catch (err: any) {
    console.log("error while Get Networks API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const CreateIdo = async (payload: FormData) => {
  console.log("Post CreateIdo API call!");
  try {
    const endPoint = "api/ido/";
    const res = await axios.post<any>(endPoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (!res?.data) throw "Something went wrong";
    console.log("Post CreateIdo API response", res);
    return res.data;
  } catch (err: any) {
    console.log("error while CreateIdo API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const GetIdo = async () => {
  //console.log("Get Ido API call!");
  try {
    const endPoint = "api/ido/";
    const res = await axios.get<Array<IdoListType>>(endPoint);
    if (!res?.data) throw "Something went wrong";
    //console.log("Get CreateIdo API response", res);
    return res.data;
  } catch (err: any) {
    console.log("error while CreateIdo API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const DeleteSingleIdo = async (id: number) => {
  console.log("Delete Single Ido API call!");
  try {
    const endPoint = `api/ido/${id}`;
    const res = await axios.delete<any>(endPoint);
    if (!res) throw "Something went wrong";
    console.log("Delete Single Ido API response", res);
    return res.status;
  } catch (err: any) {
    console.log("error while CreateIdo API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const EditIdo = async (id: string, payload: FormData) => {
  console.log("EditIdo API call!");
  try {
    const endPoint = `api/ido/${id}/`;
    const res = await axios.patch<any>(endPoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (!res?.data) throw "Something went wrong";
    console.log("EditIdo API response", res);
    return res.data;
  } catch (err: any) {
    console.log("error while CreateIdo API>>>", err.response.data);
    return Promise.reject(err);
  }
};
