import axios from "../axios";
import { AllUsers } from "../../utils/types";

export const GetAllUsers = async () => {
  //console.log("GetAllUsers API call!");
  try {
    const endPoint = "api/user/all-users/";
    const res = await axios.get<Array<AllUsers>>(endPoint);
    if (!res?.data) throw "Something went wrong";
    console.log("GetAllUsers API response", res);
    return res.data;
  } catch (err: any) {
    console.log("error while GetAllUsers API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const deleteUsers = async (userIds: number[]) => {
  try {
    const endPoint = "api/user/delete/";
    const res = await axios.post<{ message: string; deleted_count: number }>(
      endPoint,
      { user_ids: userIds }
    );
    if (!res?.data) throw "Something went wrong";
    return res.data;
  } catch (err: any) {
    console.log("error while deleting users API>>>", err.response.data);
    return Promise.reject(err);
  }
};
