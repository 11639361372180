import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { colors } from "./constants";
import { ethers } from "ethers";

export const renderHtmlString = (str: string) => (
  <div dangerouslySetInnerHTML={{ __html: str }} />
);

export const copyable = (color: string = colors.primary500) => ({
  icon: [
    <CopyOutlined style={{ color }} />,
    <CheckOutlined style={{ color }} />,
  ],
});

export function getCookie(name: string) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return undefined;
}

export function openUrlInNewTab(url: string) {
  const newTab = window.open(url, "_blank", "noopener,noreferrer");
  if (newTab) newTab.opener = null;
}

export function leftPad(number: number, targetLength: number) {
  var output = number + "";
  while (output.length < targetLength) {
    output = "0" + output;
  }
  return output;
}

export function addEllipsisToText(address: any, beforeLength: number) {
  const length = address.length;
  const start = address.slice(0, beforeLength);
  const end = address.slice(length - 3, length);
  return `${start}...${end}`;
}

export const getRemInPixels = (rem: number = 1) => {
  const temporaryElement = document.createElement("div");
  temporaryElement.style.width = `${rem}rem`;
  document.body.appendChild(temporaryElement);
  const itemWidthPixels = temporaryElement.offsetWidth;
  document.body.removeChild(temporaryElement);
  return itemWidthPixels;
};

export const maskAddressOrEmail = (input: any): string => {
  if (!input) return "";
  const MASK_CHAR = "*";
  if (input.includes("@")) {
    // For email addresses
    const [localPart, domain] = input.split("@");
    const maskedLocalPart =
      localPart.substring(0, 2) + MASK_CHAR.repeat(4) + localPart.slice(-2);
    return `${maskedLocalPart}@${domain}`;
  } else if (input.startsWith("0x")) {
    // For wallet addresses
    const maskedAddress =
      input.substring(0, 6) + MASK_CHAR.repeat(4) + input.slice(-4);
    return maskedAddress;
  }

  return input; // Return unchanged if not recognized as email or wallet address
};

export const clean = (obj: any) => {
  for (let propName in obj) {
    if (
      obj[propName] === "" ||
      obj[propName] === null ||
      obj[propName] === undefined
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

const MAX_DECIMALS = 4;

export function fromReadableAmount(amount: any, decimals: number): any {
  return ethers.utils.parseUnits(amount.toString(), decimals);
}

export function toReadableAmount(rawAmount: number, decimals: number): string {
  return ethers.utils.formatUnits(rawAmount, decimals).slice(0, MAX_DECIMALS);
}
